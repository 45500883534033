import type { TColorScheme } from "@shoshin/validators/iframe";
import React, { useEffect } from "react";

import { useEventListener } from "..";
import { sendDataToIframe } from "../utils";

export interface Props {
  roomUrl: string;
  projectId: string;
  theme?: TColorScheme;
}

const HuddleIframe: React.FC<
  React.DetailedHTMLProps<
    React.IframeHTMLAttributes<HTMLIFrameElement>,
    HTMLIFrameElement
  > &
    Props
> = ({ roomUrl, projectId, theme, ...rest }) => {
  useEventListener(
    "app:initialized",
    () => {
      if (theme) {
        sendDataToIframe({
          method: "theme",
          type: "huddle01-iframe-from-parent",
          eventData: {
            theme,
          },
        });
        sendDataToIframe({
          method: "initialize",
          type: "huddle01-iframe-from-parent",
          eventData: { projectId },
        });
      }
    },
    [theme],
  );

  useEffect(() => {
    if (theme) {
      sendDataToIframe({
        method: "theme",
        type: "huddle01-iframe-from-parent",
        eventData: {
          theme,
        },
      });
    }
  }, [theme]);

  return (
    <iframe
      id="huddle01-iframe"
      src={`${roomUrl}?projectId=${projectId}`}
      name="huddle01"
      allowFullScreen
      allow="camera; microphone; clipboard-read; clipboard-write; display-capture"
      {...rest}
    />
  );
};

export default HuddleIframe;
