import type {
  TColorScheme,
  TFeatures,
  TIframeEventData,
} from "@shoshin/validators/iframe";
import { useEffect } from "react";

import { IframeEventData } from "@shoshin/validators/iframe";

import type { THuddleEvents, THuddleEventsMap, TReaction } from "./types";
import HuddleIframe from "./components/HuddleIframe";
import { sendDataToIframe, zodErrHandler } from "./utils";

class IFrameApi {
  initialize = zodErrHandler((eventData: TIframeEventData) => {
    IframeEventData.parse(eventData);

    sendDataToIframe({
      type: "huddle01-iframe-from-parent",
      method: "initialize",
      eventData,
    });
  });
  muteMic = () => {
    sendDataToIframe({
      type: "huddle01-iframe-from-parent",
      method: "muteMic",
    });
  };
  unmuteMic = () => {
    sendDataToIframe({
      type: "huddle01-iframe-from-parent",
      method: "unmuteMic",
    });
  };
  enableWebcam = () => {
    sendDataToIframe({
      type: "huddle01-iframe-from-parent",
      method: "enableWebcam",
    });
  };
  disableWebcam = () => {
    sendDataToIframe({
      type: "huddle01-iframe-from-parent",
      method: "disableWebcam",
    });
  };
  enableShare = () => {
    sendDataToIframe({
      type: "huddle01-iframe-from-parent",
      method: "enableShare",
    });
  };
  disableShare = () => {
    sendDataToIframe({
      type: "huddle01-iframe-from-parent",
      method: "disableShare",
    });
  };
  changeAvatarUrl = (avatarUrl: string) => {
    sendDataToIframe({
      type: "huddle01-iframe-from-parent",
      method: "changeAvatarUrl",
      eventData: { avatarUrl },
    });
  };
  sendReaction = (reaction: TReaction) => {
    sendDataToIframe({
      type: "huddle01-iframe-from-parent",
      method: "sendReaction",
      eventData: { reaction },
    });
  };
  lockRoom = () => {
    sendDataToIframe({
      type: "huddle01-iframe-from-parent",
      method: "lockRoom",
    });
  };
  unlockRoom = () => {
    sendDataToIframe({
      type: "huddle01-iframe-from-parent",
      method: "unlockRoom",
    });
  };
  setTheme = (theme: TColorScheme) => {
    sendDataToIframe({
      method: "theme",
      type: "huddle01-iframe-from-parent",
      eventData: { theme },
    });
  };
  startRecording = () => {
    sendDataToIframe({
      method: "startRecording",
      type: "huddle01-iframe-from-parent",
    });
  };

  stopRecording = () => {
    sendDataToIframe({
      method: "stopRecording",
      type: "huddle01-iframe-from-parent",
    });
  };

  startLiveStreaming = (eventData: {
    streamKey: string;
    streamUrl: string;
    recordLivestream?: boolean;
  }) => {
    sendDataToIframe({
      method: "startLiveStreaming",
      type: "huddle01-iframe-from-parent",
      eventData,
    });
  };

  stopLiveStreaming = () => {
    sendDataToIframe({
      method: "stopLiveStreaming",
      type: "huddle01-iframe-from-parent",
    });
  };

  updateFeatures = (features: TFeatures) => {
    sendDataToIframe({
      method: "updateFeatures",
      type: "huddle01-iframe-from-parent",
      eventData: { features },
    });
  };
}

const iframeApi = new IFrameApi();

const useEventListener = <K extends THuddleEvents>(
  huddleEvent: K,
  cb: (data: THuddleEventsMap[K][0]) => void,
  dep?: unknown[],
) => {
  useEffect(
    () => {
      const handleIframe = (
        event: MessageEvent<{
          eventData: { data?: THuddleEventsMap[K][0]; event: THuddleEvents };
        }>,
      ) => {
        if (!event || event.data.eventData?.event !== huddleEvent) return;
        cb(event.data.eventData?.data);
      };

      window.addEventListener("message", handleIframe);

      return () => {
        window.removeEventListener("message", handleIframe);
      };
    },
    dep ? [dep] : [],
  );
};

export { HuddleIframe, iframeApi, useEventListener };
