import { z } from "zod";

export const IColorScheme = z.object({
  iconColor: z.string(),
  textColor: z.string(),
  borderColor: z.string(),
  brandColor: z.string(),
  interfaceColor: z.string(),
  onBrandColor: z.string(),
  backgroundColor: z.string(),
  accentColor: z.string(),
});

export type TColorScheme = z.infer<typeof IColorScheme>;

const Features = z.object({
  isChatEnabled: z.boolean(),
  isReactionsEnabled: z.boolean(),
  isVirtualBgEnabled: z.boolean(),
  isCopyInviteLinkEnabled: z.boolean(),
  isRecordingEnabled: z.boolean(),
  isScreenShareEnabled: z.boolean(),
  isRoomLocked: z.boolean(),
});

export type TFeatures = z.infer<typeof Features>;

export const IframeEventData = z.object({
  hostName: z.boolean().optional(), // private
  iframeEmbed: z.boolean().optional(), // private
  logoUrl: z.string().optional(),
  logoHeight: z.number().optional(),
  logoWidth: z.number().optional(),
  background: z.string().optional(),
  defaultChainId: z.number().optional(),
  redirectUrlOnLeave: z.string().optional(),
  virtualBgs: z.array(z.string()).optional(),
  walletAddress: z.string().optional(),
  theme: IColorScheme.optional(),
  projectId: z.string().optional(),
  avatarUrl: z.string().optional(),
  reaction: z.string().optional(),
  token: z.string().optional(),
  streamKey: z.string().optional(),
  streamUrl: z.string().optional(),
  recordLivestream: z.boolean().optional(),
  features: Features.optional(),
  apiKey: z.string().optional(),
});

export type TIframeEventData = z.infer<typeof IframeEventData>;

export const darkTheme: TColorScheme = {
  iconColor: "#94A3B8",
  textColor: "#f8fafc",
  borderColor: "#1C1E24",
  brandColor: "#246BFD",
  interfaceColor: "#181A20",
  onBrandColor: "#ffffff",
  backgroundColor: "#121214",
  accentColor: "#334155",
} as const;

export const lightTheme: TColorScheme = {
  iconColor: "#64748B",
  textColor: "#475569",
  borderColor: "#CBD5E1",
  brandColor: "#246BFD",
  interfaceColor: "#F1F5F9",
  onBrandColor: "#ffffff",
  backgroundColor: "#ffffff",
  accentColor: "#CBD5E1",
} as const;
