import type { TIframeEventData } from "@shoshin/validators/iframe";

import type { iframeApi } from "..";

interface IData {
  type: "huddle01-iframe-from-parent";
  method: keyof typeof iframeApi | "theme";
  eventData?: TIframeEventData;
}

export const sendDataToIframe = (eventData: IData) => {
  const huddleIframe: HTMLIFrameElement = document.getElementById(
    "huddle01-iframe",
  ) as HTMLIFrameElement;
  if (huddleIframe?.contentWindow)
    huddleIframe.contentWindow.postMessage(eventData || null, "*");
  else console.log("huddleIframe is null");
};

export const zodErrHandler =
  <T extends unknown[], U>(func: (...args: T) => U): ((...args: T) => U) =>
  (...args) => {
    try {
      return func(...args);
    } catch (err) {
      console.log(func.name + " caused an error", err);
      throw err;
    }
  };
